@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.errorPage {
    background-color: $error-color;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        color: $white-color;
        text-transform: capitalize;
        font-size: 70px;
        font-family: 'Poppins', sans-serif;
        padding: 10px;

    }

    .description {
        color: $white-color;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        padding: 10px;
    }

    .button{
        margin: 30px;
    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.dashboardLayout {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

    .dashboardContainer {
        display: flex;
        flex: 1;
        width: 100vw;
        position: relative;

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            background-color: $light-bg-color;
            box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        .bottomArea {
            margin-top: auto;
            text-align: center;

            .copyRightText {
                padding: 10px 0;
                font-size: 14px;
                font-weight: 300;
                font-family: 'Poppins', sans-serif;

                .link {
                    color: $primary-color;
                    transition: all 0.3s;

                    &:hover {
                        cursor: pointer;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.addPatient {

    display: flex;
    justify-content: center;
    align-items: center;
    
    .box {
        background-color: $bg-color;
        padding: 20px;
        max-width: 80vw;
        min-width: 60vw;
        border-radius: 10px;

        &:focus-visible {
            outline: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .title {
            margin: 10px 30px 30px;
            text-align: center;
            color: $primary-color;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            white-space: pre-wrap;
            font-weight: 200;
        }

      
        .buttons {
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }

        .fileSection {
            margin: 30px 0;


            .file {
                padding: 10px;
                border: 1px solid $primary-color;
                border-radius: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                .left {
                    .fileName {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }

                    .fileSize {
                        font-size: 12px;
                        font-weight: 300;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

            }

            .transcribeBtn {
                margin: 30px 0;
                text-align: center;
            }
        }

        .statusBox {
            max-width: 600px;
            padding: 15px;
            background-color: $in-progress-color;

            filter: alpha(opacity=90);
            /* IE */
            -moz-opacity: 0.9;
            /* Mozilla */
            opacity: 0.9;
            /* CSS3 */
            border-radius: 5px;
            text-align: center;
            margin: auto;

            &.error {
                max-width: unset;
                background-color: $light-bg-primary-color;

                .text {
                    font-size: 15px;
                    font-weight: 300;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;
                    color: $error-color;
                }
            }

            .text {
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
                color: $white-color;
            }
        }
        ul, ol {
            list-style-type: none;
            padding: 5px;
        }

        .uploadSection {
            margin: 30px 0;
            border: 1px solid $grey-color;
            background-color: $light-bg-color;
            border-radius: 5px;

            .dropzone {
                padding: 20px;

                .uploadText {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;
                    text-align: center;

                    .clickable {
                        color: $primary-color;

                        &:hover {
                            font-weight: 400;
                            cursor: pointer;
                        }
                    }
                }
            }

        }

    }

}
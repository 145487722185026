@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.footer {
    padding: 80px 7vw 20px;
    background-color: $black-bg-color;
    
@include sm {
        padding: 15px 7vw;
    }

    .logoArea {
        display: flex;
        justify-content: space-between;
        .logo {
            height: 80px;
        
            @include md {
                    height: 50px;
                }

            @include sm {
                    height: 30px;
                }
        }
        .badge{
            height: 80px;
            @include md {
                    height: 50px;
                }

            @include sm {
                    height: 30px;
                }
        }
    }

    .sectionsArea {
        margin: 30px;
        padding: 10px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 10px;

        .section {

            .title {
                font-size: 15px;
                margin: 20px 0px;
                font-weight: 400;
                font-family: 'Poppins', sans-serif;
                color: $white-color;
            }

            .navLink {
                display: block;
                margin: 10px 0px;
                font-size: 14px;
                font-weight: 300;
                font-family: 'Poppins', sans-serif;
                transition: all 0.3s;
                color: $grey-color;

                &:hover {
                    color: $primary-color;

                }
            }

        }
    }

    .bottomArea {
        border-top: 1px solid $grey-border-color;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .copyRightText {
            padding: 20px 0;
            font-size: 14px;
            font-weight: 300;
            font-family: 'Poppins', sans-serif;
            color: $white-color;

            .link {
                color: $primary-color;
                transition: all 0.3s;

                &:hover {
                    cursor: pointer;
                    font-weight: 400;
                }
            }
        }
    
    }
}
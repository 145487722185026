@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.users {
    flex: 1;
    padding: 10px 20px;

    &__title {
        margin: 10px 0 20px;
        font-size: 17px;
        font-weight: 400;
        color: $black-color;
        text-transform: capitalize;
        font-family: 'Poppins', sans-serif;
    }

    .paper {
        &::-webkit-scrollbar {
            display: none;
        }

        .tableContainer {
            .table {
                .caption {
                    text-align: center;
                    font-size: 13px;
                    font-weight: 300;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;
                }

                .head {
                    .cell {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }
                }

                .body {
                    .cell {

                        .roleName {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            font-family: 'Poppins', sans-serif;
                            text-transform: capitalize;
                        }

                        .text {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            font-family: 'Poppins', sans-serif;
                        }

                        .name {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            font-family: 'Poppins', sans-serif;
                        }

                        .contact {
                            .single {
                                display: flex;
                                gap: 20px;
                                align-items: center;
                                margin: 5px 0;

                                &__text {
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: normal;
                                    font-family: 'Poppins', sans-serif;
                                }
                            }


                        }

                        .badge {}

                        .buttons {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                        }
                    }
                }
            }
        }
    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.alert {

    display: flex;
    justify-content: center;
    align-items: center;

    .box {
        background-color: $bg-color;
        padding: 10px;
        max-width: 80vw;
        border-radius: 10px;

        &:focus-visible {
            outline: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .header {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .title {
            margin: 10px 30px 30px;
            text-align: center;
            color: $primary-color;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            white-space: pre-wrap;
            font-weight: 200;
        }

        .buttons {
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
    }

}
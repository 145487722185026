@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.home {
    position: relative;

    .mainSection {
        min-height: 100vh;
        height: 100%;
        display: flex;
        background-color: $bg-color;


        .textArea {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 80px 7vw;

            @include sm {
                padding: 15px 7vw;
            }

            .title {
                font-size: 40px;
                font-weight: 600;
                line-height: 1.2;
                font-family: 'Poppins', sans-serif;
                margin: 10px 0;

                @include md {
                    font-size: 35px;
                    font-weight: 500;
                }

                @include sm {
                    font-size: 30px;
                    font-weight: 400;
                }
            }

            .description {
                margin: 20px 0;
                font-size: 18px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Poppins', sans-serif;

                @include md {
                    font-size: 16px;
                    font-weight: 300;
                }

                @include sm {
                    font-size: 15px;
                    font-weight: 200;
                }
            }

            .buttons {
                display: flex;
                flex-wrap: wrap;
                margin: 20px 0;
                gap: 20px;
            }
        }

        .imageArea {
            flex: 1;
            padding: 40px;
            background-image: url("../../images/bgImage.jpeg");
            background-repeat: no-repeat;
            background-size: cover;
            clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
            display: block;

            @include md {
                display: none;
            }

        }
    }

    .serviceSection {
        min-height: 100vh;
        height: 100%;
        padding: 80px 0;
        display: flex;
        flex-wrap: wrap;
        background-color: $bg-color;

        .textArea {
            min-width: 250px;
            padding: 40px 7vw;
            flex: 1;

            @include sm {
                    padding: 15px 7vw;
                }

            .title {
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
            }

            .description {
                font-size: 40px;
                font-weight: 600;
                line-height: 1.2;
                font-family: 'Poppins', sans-serif;
                margin: 20px 0;

                @include md {
                    font-size: 35px;
                    font-weight: 500;
                }

                @include sm {
                    font-size: 30px;
                    font-weight: 400;
                }
            }
        }

        .serviceArea {
            flex: 2;
            display: flex;
            padding: 0 5vw ;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;

            .serviceBox {
                padding: 20px;
                flex: 1;
                min-width: 350px;

                @include sm {
                    min-width: 250px;
                }

                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .iconArea {
                    background-color: $primary-color;
                    padding: 20px;
                    border-radius: 10px;
                    margin: 10px 0;

                    .icon {
                        color: $white-color;
                        font-size: 30px;

                    }
                }

                .title {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;
                    margin: 10px 0;
                }

                .description {

                    font-size: 15px;
                    font-weight: 300;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;

                }

            }
        }

    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.newEncounter {
    flex: 1;
    padding: 10px 20px;

    &__title {
        font-size: 17px;
        font-weight: 400;
        color: $black-color;
        text-transform: capitalize;
        font-family: 'Poppins', sans-serif;
        margin: 10px 0;
    }

    .topContent {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        flex-wrap: wrap;

        .patientArea {
            flex: 3;
            min-width: 500px;

            @include md {
                min-width: 400px;
            }

            @include sm {
                min-width: 200px;
            }

            border: 1px solid $white-color;
            border-radius: 5px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1),
            0 6px 6px rgba(0, 0, 0, 0.2);
            background-color: $white-color;
            padding: 10px 20px;

            .show {
                opacity: 1;
                max-height: unset;
                animation-name: showAnimate;
                animation-duration: 1s;
            }

            @keyframes showAnimate {
                0% {
                    max-height: 0px;
                    opacity: 0;
                }

                100% {
                    max-height: 300px;
                    opacity: 1;

                }
            }

            .hide {
                opacity: 1;
                max-height: unset;
                animation-name: hideAnimate;
                animation-duration: 1s;
            }

            @keyframes hideAnimate {
                0% {
                    opacity: 0;
                    max-height: 300px;
                    height: 60px;
                }

                100% {
                    opacity: 1;
                    max-height: 0px;
                    height: 0px;
                }
            }

            .details {
                margin: 10px 0;
                display: grid;
                gap: 20px;
                grid-template-columns: 2fr 1fr 1fr;

                @include lg {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }

        .dateArea {
            min-width: 250px;
            flex: 1;
            border: 1px solid $white-color;
            border-radius: 5px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
            background-color: $white-color;
            padding: 10px 20px;
        }


    }

    .mainContent {


        .typeArea {
            padding: 10px;
            width: 100%;

            &__title {
                font-size: 15px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
                margin: 5px 0;

                &.Mui-focused {
                    color: $primary-color;
                }
            }

            .radioGroup {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 30px;

                @include sm {
                    flex-direction: column;
                    gap: 10px;
                }

                .radioLabel {
                    & .MuiTypography-root {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }

                    & .Mui-checked {
                        color: $primary-color;
                    }

                }
            }
        }

        .noteForm {
            border: 1px solid $white-color;
            border-radius: 5px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1),
                0 6px 6px rgba(0, 0, 0, 0.2);
            background-color: $white-color;
            padding: 20px;

            .field {
                margin: 10px 0;
            }

            .button {
                text-align: center;
            }
        }

        .transcriptionForm {
            border: 1px solid $white-color;
            border-radius: 5px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1),
                0 6px 6px rgba(0, 0, 0, 0.2);
            background-color: $white-color;
            padding: 20px;

            & .MuiToggleButton-root {
                &.Mui-selected {
                    background-color: $light-primary-color;
                }
            }

            .expandSection {
                opacity: 1;
                max-height: unset;
                animation-name: showAnimate;
                animation-duration: 1s;
            }

            @keyframes showAnimate {
                0% {
                    max-height: 0px;
                    opacity: 0;
                }

                100% {
                    max-height: 300px;
                    opacity: 1;

                }
            }

            .hideSection {
                opacity: 1;
                max-height: unset;
                animation-name: hideAnimate;
                animation-duration: 1s;
            }

            @keyframes hideAnimate {
                0% {
                    opacity: 0;
                    max-height: 300px;
                    height: 60px;
                }

                100% {
                    opacity: 1;
                    max-height: 0px;
                    height: 0px;
                }
            }

            .uploadSection {
                margin: 30px 0;

                border: 1px solid $grey-color;
                background-color: $light-bg-color;
                border-radius: 5px;

                .dropzone {
                    padding: 20px;

                    .uploadText {
                        font-size: 16px;
                        font-weight: 300;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                        text-align: center;

                        .clickable {
                            color: $primary-color;

                            &:hover {
                                font-weight: 400;
                                cursor: pointer;
                            }
                        }
                    }
                }

            }

            .fileSection {
                margin: 30px 0;


                .file {
                    padding: 10px;
                    border: 1px solid $primary-color;
                    border-radius: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;

                    .left {
                        .fileName {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: normal;
                            font-family: 'Poppins', sans-serif;
                            margin: 5px 0 10px;
                        }

                        .fileSize {
                            font-size: 12px;
                            font-weight: 300;
                            line-height: normal;
                            font-family: 'Poppins', sans-serif;
                        }
                    }

                    .right {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }

                }

                .transcribeBtn {
                    margin: 30px 0;
                    text-align: center;
                }
            }

            .recordSection {
                margin: 30px 0;
                text-align: center;

                .timer {
                    margin: 20px 0;

                    .text {
                        font-size: 30px;
                        font-weight: 500;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }
                }

                .recordButton {
                    margin: 20px 0;
                    .check-box{
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .hover-text{
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            font-style: italic;
                            font-family: 'Poppins', sans-serif;
                        }
                    }
                }

                .blobSection {
                    margin: 30px 0;

                    .blob {
                        padding: 10px;
                        border: 1px solid $primary-color;
                        border-radius: 15px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;

                        .left {
                            .blobName {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: normal;
                                font-family: 'Poppins', sans-serif;
                                margin: 5px 0 10px;
                                text-align: left;
                            }

                            .blobSize {
                                font-size: 12px;
                                text-align: left;
                                font-weight: 300;
                                line-height: normal;
                                font-family: 'Poppins', sans-serif;
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                        }
                    }

                    .transcribeBtn {
                        margin: 30px 0;
                        text-align: center;
                    }
                }
            }

        }
    }

    .statusBox {
        max-width: 600px;
        padding: 10px;

        filter: alpha(opacity=90);
        /* IE */
        -moz-opacity: 0.9;
        /* Mozilla */
        opacity: 0.9;
        /* CSS3 */
        border-radius: 5px;
        text-align: center;
        margin: auto;

        .text {
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            font-family: 'Poppins', sans-serif;
            color: $error-color;
        }
    }

}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.login {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: 100%;

    .imageArea {
        flex: 1;
        padding: 40px;
        background-image: url("../../images/bgImage.jpeg");
        background-repeat: no-repeat;
        background-size: cover;

        @include md {
            display: none;
        }
    }

    .loginArea {
        flex: 1;
        padding: 40px 40px 10px;
        @include md {
                padding: 50px 30px 10px;
            }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .titleText {
            text-align: center;
            font-size: 40px;
            font-weight: 600;
            line-height: 1.2;
            font-family: 'Poppins', sans-serif;
            margin: 10px 0 40px;

            @include md {
                font-size: 35px;
                font-weight: 500;
            }

            @include sm {
                font-size: 30px;
                font-weight: 400;
            }
        }

        .separation {
            width: 80%;
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 10px auto 20px;

            @include md {
                width: 100%;
                margin: 10px auto 20px;
            }

            .line {
                flex: 1;
                height: 1px;
                background-color: $grey-color;
            }

            .separationText {
                font-size: 12px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
                color: $grey-border-color;
            }
        }

        .loginForm {
            width: 80%;
            margin: 20px auto 20px;

            @include md {
                width: 100%;
                margin: 20px auto 20px;
            }

            .field {
                margin: 30px 0;

                @include md {
                    margin: 10px 0;
                }
            }

            .forgetPassword {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin: 30px 0;

                @include md {
                    margin: 10px 0;
                }

                .button {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;
                    color: $primary-color;
                    transition: all 0.3s;

                    &:hover {
                        cursor: pointer;
                        color: $black-color;
                    }

                }
            }

            .buttons {
                display: flex;
                gap: 20px;
                align-items: center;
                margin: 30px 0;

                @include md {
                    margin: 20px 0;
                }
            }

            .privacyTermsSection {
                font-size: 14px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
                color: $grey-border-color;

                .navLink {
                    color: $primary-color;
                    font-weight: 300;
                    font-size: 14px;
                    transition: all 0.3s;

                    &:hover {
                        font-weight: 400;
                    }
                }
            }
        }

        .bottomArea {
            margin-top: auto;

            .copyRightText {
                text-align: center;
                padding: 10px 0;
                font-size: 14px;
                font-weight: 300;
                font-family: 'Poppins', sans-serif;

                .link {
                    color: $primary-color;
                    transition: all 0.3s;

                    &:hover {
                        cursor: pointer;
                        font-weight: 400;
                    }
                }
            }
        }


    }
}
@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.custom-icon {
    color: $grey-color;
    border-color: $light-bg-color;
    transition: all 1s;

    &:hover {
        color: $main-bg;
    }

    &:active {
        color: $main-bg;
    }

    &:disabled {
        color: $bg-color;

        &:hover {
            cursor: not-allowed;
        }
    }

    &.small {
        font-size: 12px;
    }

    &.medium {
        font-size: 20px;
    }

    &.large {
        font-size: 28px;
    }

    &.margin-small {
        margin: 5px;
    }

    &.margin-medium {
        margin: 10px;
    }

    &.margin-large {
        margin: 15px;
    }
}

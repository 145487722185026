@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";


.mainHeader {
    position: absolute;
    background-color: $white-color;
    z-index: 100;
    width: 90vw;
    top: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 20px;
    border: 1px solid $white-color;
    border-radius: 50px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .left {
        .logo {
            height: 30px;
            width: auto;
            transition: all 0.3s;

            @include sm {
                height: 20px;
            }

            &:hover {
                cursor: pointer;
                scale: 105%;
            }
        }
    }

    .right {
        display: flex;
        gap: 50px;
        align-items: center;

        .navButtons {
            display: flex;
            align-items: center;
            gap: 20px;

            .navLink {
                font-size: 15px;
                font-weight: 500;
                font-family: 'Poppins', sans-serif;
                transition: all 0.3s;

                &:hover {
                    color: $primary-color;
                    scale: 105%;
                }

                &.active {
                    color: $primary-color;
                }
            }
        }

        .mainButtons {
            display: flex;
            align-items: center;
            gap: 10px;
        }


    }
}

.drawer {
    width: 250px;
    background-color: $bg-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .navButtons {
        margin: 20px;

        .navLink {
            margin: 20px;
            text-align: center;
            display: block;
            font-size: 15px;
            font-weight: 500;
            font-family: 'Poppins', sans-serif;
            transition: all 0.3s;

            &:hover {
                color: $primary-color;
                scale: 105%;
            }

            &.active {
                color: $primary-color;
            }
        }
    }

    .mainButtons {
        margin: 20px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.preference {
    flex: 1;
    padding: 10px 20px;


    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 10px;


        &--title {
            font-size: 17px;
            font-weight: 400;
            color: $black-color;
            text-transform: capitalize;
            font-family: 'Poppins', sans-serif;
        }

    }

    .box {
        border: 1px solid $white-color;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
        background-color: $white-color;
        padding: 15px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: auto;
     
        .toggleBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom:.1px solid $grey-border-color;
            padding: 15px 10px;
            .left{
                .title{
                 font-weight: 500;
                 font-size: 15px;
                }
                .description{
                 font-weight: 300;
                 font-size: 13px;
                }

            }

            .switch{
                .Mui-checked{
                    color: $primary-color;
                }
                
                .Mui-checked+.MuiSwitch-track{
                    background-color: $light-primary-color;
                }
            }
    
        }
        .toggleBox:last-child{
        border-bottom: none;
        }
    }






}
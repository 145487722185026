@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.editPatient {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    .box {
        background-color: $bg-color;
        padding: 10px 20px;
        width: 40vw;
        max-height: 80vh;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-radius: 10px;

        @include md {
            width: 60vw;
        }

        @include sm {
            width: 80vw;
        }

        &:focus-visible {
            outline: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
                color: $black-color;
                text-transform: capitalize;
            }

        }

        .mainContent {
            padding: 20px 0px;
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(1, 1fr);
            font-family: "Poppins", sans-serif;

            .details {
                width: 80%;
                margin: auto;
                .top{
                    padding-bottom: 10px;
                }
                .bottom{
                    gap : 20px;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    @include sm {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
            .button{
                margin:auto;
            }

        }

    }
}
@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.mainTextArea {
    .title {
        font-size: 15px;
        font-weight: 300;
        line-height: normal;
        font-family: 'Poppins', sans-serif;
        margin: 5px 0;
    }

    .textArea {
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        border: 1px solid $grey-color;
        padding: 5px 5px;
        width: 100%;
        margin: 5px 0 5px;
        border-radius: 5px;
        outline: none;
        transition: all 0.3s;
        resize: vertical;

        &:not(:disabled) {

            &:hover,
            &:focus-within {
                border-color: $primary-color;
                box-shadow: 0px 0px 3px 1px $primary-color;
            }
        }

        &:disabled {
            background-color: $disabled-bg-color;
            color: $black-color;
            &:hover {
                cursor: not-allowed;
            }
        }


        &.error {

            &:hover,
            &:focus-within {
                border-color: $error-color;
                box-shadow: 0px 0px 3px 1px $error-color;
            }
        }
    }

    .errorText {
        color: $error-color;
        font-size: 10px;
        font-family: 'Poppins', sans-serif;
    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.resetPassword {
    flex: 1;
    padding: 10px 20px;

    &__title {
        font-size: 17px;
        font-weight: 400;
        color: $black-color;
        text-transform: capitalize;
        font-family: 'Poppins', sans-serif;
        margin: 10px 0;
    }

    .mainContent {
        border: 1px solid $white-color;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
        background-color: $white-color;
        padding: 20px;

        .resetPasswordForm {
            max-width: 700px;
            margin: 20px auto;

            .description {
                font-size: 15px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
                margin: 20px 0;

            }

            .field {
                margin: 30px 0;
            }

            .button {
                margin: 30px 0;
                text-align: center;
            }
        }
    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.userInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    .box {
        background-color: $bg-color;
        padding: 10px 20px;
        width: 90vw;
        max-height: 80vh;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-radius: 10px;

        @include md {
            width: 95vw;
        }

        @include sm {
            width: 95vw;
        }

        &:focus-visible {
            outline: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
                color: $black-color;
                text-transform: capitalize;
            }

        }
        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .heading {
                font-size: 17px;
                font-weight: 400;
                color: $black-color;
                text-transform: capitalize;
                font-family: 'Poppins', sans-serif;
                margin: 10px 0;
            }
            .drop-downs{
                .drop-down{
                    width: 200px;
                }
            }
        }
    
        .mainContent {
            padding: 20px 0px;
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(3, 1fr);
            font-family: "Poppins", sans-serif;
    
            @include md {
                grid-template-columns: repeat(2, 1fr);
            }
            @include sm {
                grid-template-columns: repeat(1, 1fr);
            }
    
            .small-box {
                border: 1px solid $light-bg-color;
                border-radius: 5px;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
                background-color: $white-color;
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: auto;
    
    
                .body {
    
                    .heading {
                        font-size: 15px;
                        font-weight: 300;
                        margin-bottom: 10px;
                    }
    
                    .total {
                        font-size: 30px;
                        font-weight: 700;
                    }
                }
    
                .icon {
                    font-size: 50px;
                }
            }
    
            .big-box {
                border: 1px solid $light-bg-color;
                border-radius: 5px;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
                background-color: $white-color;
                padding: 10px;
                margin-bottom: auto;
    
                .basic-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;
    
                    .body {
                        .heading {
                            font-size: 15px;
                            font-weight: 300;
                            word-break: break;
                            margin-bottom: 10px;
                        }
    
                        .total {
                            font-size: 30px;
                            font-weight: 700;
                        }
                    }
    
                    .icon {
                        font-size: 50px;
                    }
                }
    
                .data-grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    padding: 10px;
                    gap: 12px;
    
                    .item {
                        // &:nth-child(even) {
                        //     justify-self: flex-end;
                        //   }
                        .label {
                            font-size: 12px;
                            font-weight: 300;
                            word-break: break;
                        }
    
                        .value {
                            font-size: 13px;
                            font-weight: 500;
                        }
                    }
    
                }
            }
        }

    }
}
@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.mainDatePicker {
    .title {
        font-size: 15px;
        font-weight: 300;
        line-height: normal;
        font-family: 'Poppins', sans-serif;
        margin: 5px 0;
    }

    .picker {
        margin: 5px 0 5px;

        input {
            padding: 8px 5px;
            font-size: 15px;
            font-family: 'Poppins', sans-serif;
            background-color: $white-color;
        }

        .MuiOutlinedInput-notchedOutline {
            border: 1px solid $grey-color;

        }

        .Mui-disabled {
            cursor: not-allowed;
            background-color: $disabled-bg-color;
            color: $black-color;
            -webkit-text-fill-color: $black-color;
        }

        .Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border-color: $primary-color;
                box-shadow: 0px 0px 3px 1px $primary-color;
                outline: none;
            }
        }

        .MuiInputAdornment-root {
            .MuiButtonBase-root {}
        }
    }


}
@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.dashboardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    
    .left {
        display: flex;
        align-items: center;
        gap: 40px;
        .imageArea {
            .logo {
                height: auto;
                max-height: 40px;
                transition: all 0.5s;

                &:hover {
                    cursor: pointer;
                    scale: 105%;
                }
            }
        }
    }

    .right {}
}
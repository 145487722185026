@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.sideMenu {
    padding: 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
    height: 100%;
    background-color: $white-color;
    transition: all 0.5s;
    z-index: 1000;
    @include md {
        position: absolute;
    }

    .item {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        transition: all 0.5s;

        .title {
            font-size: 12px;
            font-weight: 200;
            color: $black-color;
            text-transform: uppercase;


        }

        .listItem {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border-radius: 5px;


            &:hover {

                .icon {
                    color: $primary-color;
                }

                .listItemTitle {
                    color: $primary-color;
                }
            }


            &.active {
                background-color: $light-primary-color;
            }

            &.button {
                background-color: $secondary-color;
                padding: 10px;
                border-radius: 30px;
                margin: 10px 0;

                .icon {
                    color: $white-color;
                }

                .listItemTitle {
                    color: $white-color;
                }

                &:hover {
                    background-color: $black-color;
                }
            }

            .listItemTitle {
                font-size: 15px;
                font-weight: 400;
                color: $black-color;
                text-transform: capitalize;

            }
        }
    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.forgotPassword {
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
        background-color: $bg-color;
        padding: 10px;
        width: 50vw;
        max-height: 70vh;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-radius: 10px;

        @include md {
                width: 70vw;
        }
                @include sm {
                    width: 90vw;
                }

        &:focus-visible{
            outline: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 18px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
                color: $primary-color;
            }

        }

        .mainSection {
            padding: 30px;

            .mainText {
                margin: 20px 0;
                font-size: 15px;
                    font-weight: 300;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;
            }

            .field {
                margin: 20px 0;
            }
        }

        .button {
            margin: 40px 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;
        }

    }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.termsConditions {

    .titleArea {
        padding: 100px 7vw 50px;
        min-height: 20vh;
        background-color: $light-bg-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            font-family: 'Poppins', sans-serif;
        }

        .description {
            margin-top: 5px;
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            line-height: normal;
            font-family: 'Poppins', sans-serif;
        }
    }

    .mainSection {
        padding: 50px 7vw;
        display: flex;
        justify-content: center;
        align-items: center;

        @include md {
            display: block;
        }

        .textArea {
            padding: 20px;
            max-width: 1000px;

            .title {
                margin: 10px 0;
                font-size: 15px;
                font-weight: 500;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
            }

            .detail {
                margin: 10px 0;
                font-size: 14px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Poppins', sans-serif;
            }

            .point {
                margin: 5px 0;
                font-size: 14px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Poppins', sans-serif;

                .main {
                    font-weight: 500;
                }
            }

            .button {
                margin: 20px 0;
                text-align: center;
            }

        }
    }

}
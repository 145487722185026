$main-bg: #2a3447;
$bg-color: #fff;
$primary-color: #6D63AC;
$secondary-color: #F9AA20;
$text-color: #201C32;
$success-color : #009432;
$dark-success-color: #007227;
$error-color: #c0392b;
$dark-error-color: #a43125;
$white-color: #fff;
$black-color : #000;
$grey-color: #BEC0C2;
$black-bg-color: #151414;
$grey-border-color: #2B333F;
$light-bg-color: #eff1f6;
$light-primary-color : #B6B1D5;
$disabled-bg-color: #f7f7f7;
$in-progress-color: #3498db;
$light-bg-primary-color: #DFDDED;

@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.encounterDetail {
    flex: 1;
    padding: 10px 20px;
    max-width: 100vw;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0 5px;


        &--title {
            font-size: 17px;
            font-weight: 400;
            color: $black-color;
            text-transform: capitalize;
            font-family: 'Poppins', sans-serif;
        }

        .rightBtn {
            margin-right: 10px
        }


    }

    .topContent {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        flex-wrap: wrap;

        .leftArea {
            flex: 3;
            min-width: 500px;
            height: 100%;

            @include md {
                min-width: 400px;
            }

            @include sm {
                min-width: 200px;
            }

            .top {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                .patientArea {
                    flex: 3;
                    min-width: 500px;

                    @include md {
                        min-width: 400px;
                    }

                    @include sm {
                        min-width: 200px;
                    }

                    border: 1px solid $white-color;
                    border-radius: 5px;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1),
                    0 6px 6px rgba(0, 0, 0, 0.2);
                    background-color: $white-color;
                    padding: 5px 10px;

                    .show {
                        opacity: 1;
                        max-height: unset;
                        animation-name: showAnimate;
                        animation-duration: 1s;
                    }

                    @keyframes showAnimate {
                        0% {
                            max-height: 0px;
                            opacity: 0;
                        }

                        100% {
                            max-height: 300px;
                            opacity: 1;

                        }
                    }

                    .hide {
                        opacity: 1;
                        max-height: unset;
                        animation-name: hideAnimate;
                        animation-duration: 1s;
                    }

                    @keyframes hideAnimate {
                        0% {
                            opacity: 0;
                            max-height: 300px;
                            height: 60px;
                        }

                        100% {
                            opacity: 1;
                            max-height: 0px;
                            height: 0px;
                        }
                    }

                    .rightButtons {
                        display: flex;
                        justify-content: flex-end;

                        .buttons {
                            display: flex;
                            gap: 10px;
                        }
                    }

                    .details {
                        margin: 10px 0;
                        display: grid;
                        gap: 20px;
                        grid-template-columns: repeat(3, 1fr);

                        @include lg {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    }
                }

                .dateArea {
                    flex: 1;
                    min-width: 200px;
                    border: 1px solid $white-color;
                    border-radius: 5px;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
                    background-color: $white-color;
                    padding: 5px 10px;

                    .rightButtons {
                        display: flex;
                        justify-content: flex-end;

                        .buttons {
                            display: flex;
                            gap: 10px;
                        }

                    }

                }
            }



            .majorArea {
                margin: 20px 0 0;
                border: 1px solid $white-color;
                border-radius: 5px;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1),
                    0 6px 6px rgba(0, 0, 0, 0.2);
                background-color: $white-color;
                padding: 10px 20px;
                text-align: center;
                height: 100%;

                &.action {
                    background-color: $light-bg-primary-color;
                    border: 1px solid $light-bg-primary-color;

                    
                }

                .approve {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;


                    .text {
                        font-size: 20px;
                        font-weight: 400;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                        color: $success-color;
                    }
                }

                .statusBox {
                    max-width: 600px;
                    padding: 10px;
                    background-color: $in-progress-color;

                    filter: alpha(opacity=90);
                    /* IE */
                    -moz-opacity: 0.9;
                    /* Mozilla */
                    opacity: 0.9;
                    /* CSS3 */
                    border-radius: 5px;
                    text-align: center;
                    margin: auto;

                    &.error {
                        max-width: unset;
                        background-color: $light-bg-primary-color;

                        .text {
                            font-size: 12px;
                            font-weight: 300;
                            line-height: normal;
                            font-family: 'Poppins', sans-serif;
                            color: $error-color;
                        }
                    }

                    .text {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                        color: $white-color;
                    }
                }

                .buttons {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    flex-wrap : wrap;
                }
            }
        }



        .rightArea {
            min-width: 200px;
            flex: 1;
            align-self: stretch;


            .detailArea {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border: 1px solid $white-color;
                border-radius: 5px;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
                background-color: $white-color;
                padding: 5px 20px;

                .keyValue {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    justify-content: space-between;

                    .key {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }

                    .value {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }
                }
                .error-message {
                    margin: 0;
                    text-align: center;
                    font-size: 13px;
                    color: $error-color;
                    margin: 10px 0px;
                    font-style: italic;
                }

                .button {
                    margin: 0;
                    text-align: center;
                }
            }
        }



    }

    .splitView {
        margin: 20px 0 5px;

        .splitViewToggleGroup {
            background-color: $white-color;

            .splitViewToggleBtn {
                color: $grey-color;

                &.Mui-selected {
                    background-color: $light-bg-primary-color;
                    color: $primary-color;
                }
            }


        }
    }

    .bottomContent {
        margin: 10px 0;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;




        .tabView {
            flex: 1;
            margin: 10px 0;
            border: 1px solid $white-color;
            border-radius: 5px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
            background-color: $white-color;
            max-width: calc(100vw - 40px);

            .MuiTabs-scrollButtons.Mui-disabled {
                opacity: 0.3;
            }

            .MuiTab-root {


                &.Mui-selected {
                    color: $primary-color;
                }

            }

            .MuiTabs-indicator {
                background-color: $primary-color;
            }

            .mainView {

                .tabElement {
                    padding: 10px 20px;

                    .rightButtons {
                        display: flex;
                        justify-content: flex-end;
                        margin: 0px 10px 10px;

                        .buttons {
                            display: flex;
                            gap: 10px;
                        }

                    }

                    .dataText {
                        padding: unset;
                        white-space: pre-wrap;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }
                }

                .dataText {
                    padding: 20px;
                    white-space: pre-wrap;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;
                }

            }
        }
    }
}
@import "./variables.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}


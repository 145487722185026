@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.encounters {
    flex: 1;
    padding: 10px 20px;


    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 10px;


        &--title {
            font-size: 17px;
            font-weight: 400;
            color: $black-color;
            text-transform: capitalize;
            font-family: 'Poppins', sans-serif;
        }

        .rightBtn {
            margin-right: 10px
        }


    }

    .paper {
        &::-webkit-scrollbar {
            display: none;
        }

        .tableContainer {
            .table {
                .caption {
                    text-align: center;
                    font-size: 13px;
                    font-weight: 300;
                    line-height: normal;
                    font-family: 'Poppins', sans-serif;
                }

                .head {
                    .cell {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: normal;
                        font-family: 'Poppins', sans-serif;
                    }
                }

                .body {
                    .cell {
                        .text {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            font-family: 'Poppins', sans-serif;
                        }

                        .patient {
                            .patientName {
                                font-size: 13px;
                                font-weight: 400;
                                line-height: normal;
                                font-family: 'Poppins', sans-serif;
                            }

                            .patientEmrId {
                                margin-top: 5px;
                                font-size: 12px;
                                font-weight: 300;
                                line-height: normal;
                                font-family: 'Poppins', sans-serif;
                            }
                        }

                        .type {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .typeText {
                                font-size: 13px;
                                font-weight: 400;
                                line-height: normal;
                                font-family: 'Poppins', sans-serif;
                            }

                            .status {
                                background-color: $primary-color;
                                border-radius: 4px;
                                padding: 2px 5px;
                                color: $white-color;
                                margin-top: 5px;
                                font-size: 12px;
                                font-weight: 300;
                                line-height: normal;
                                font-family: 'Poppins', sans-serif;
                            }
                        }

                        .badge {}

                        .buttons {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                        }
                    }
                }
            }
        }
    }

}
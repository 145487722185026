@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.contactUs {
    .titleArea {
        padding: 100px 7vw 50px;
        min-height: 20vh;
        background-color: $light-bg-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            font-family: 'Poppins', sans-serif;
        }

        .description {
            margin-top: 5px;
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            line-height: normal;
            font-family: 'Poppins', sans-serif;
        }
    }

    .mainSection {
        padding: 50px 7vw;
        max-width: 900px;
        margin: auto;

        .field {
            margin: 30px 0;
        }

        .phoneGroup {
            margin: 30px 0;
            display: flex;
            align-items: center;
            gap: 30px;

            @include md {
                display: block;
            }

            .countryCodeField {
                flex: 1;
            }

            .phoneField {
                flex: 1;

                @include md {
                    margin: 30px 0;
                }
            }
        }

        .button {
            margin: 30px 0;
            text-align: center;
        }

    }
}